<template>
  <Layout>
    <BHeader>
      <el-form
        ref="form"
        :model="formData"
        :rules="searchRules"
        inline
      >
        <el-form-item prop="dateRange">
          <el-date-picker
            v-model="formData.dateRange"
            type="daterange"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item prop="countryCodeList">
          <el-select
            v-model="formData.countryCodeList"
            style="width: 240px"
            multiple
            clearable
            filterable
            :filter-method="filterMethod"
            collapse-tags
            value-key="countryCode"
            placeholder="Country"
            @visible-change="filterNationList = nationList"
          >
            <div class="pre-header">
              <div
                class="select-all"
                @click="changeSelectState('countryCodeList', nationList, formData)"
              >
                {{ selectButtonLabel(formData.countryCodeList, nationList) }}
              </div>
            </div>
            <el-option
              v-for="item in filterNationList"
              :key="item.countryCode"
              :label="item.nation"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="channelList">
          <el-select
            v-model="formData.channelList"
            style="width: 308px"
            multiple
            clearable
            filterable
            collapse-tags
            placeholder="Channel"
          >
            <div class="pre-header">
              <div
                class="select-all"
                @click="changeSelectState('channelList', channelList.map(item => item.value), formData)"
              >
                {{ selectButtonLabel(formData.channelList, channelList) }}
              </div>
            </div>
            <el-option
              v-for="(item, index) in channelList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            Search
          </el-button>
        </el-form-item>
      </el-form>
      <div slot="right">
        <el-button
          class="download-icon"
          type="text"
          :loading="downloadLoading"
          @click="addFinancialDownloadTask"
        >
          <i
            title="下载"
            class="fa fa-cloud-download icondown"
          />
        </el-button>
      </div>
    </BHeader>

    <div
      v-loading="listInfo.loading"
      class="overview"
    >
      <div class="items">
        <div class="num">
          {{ listInfo.sumRevenue || '-' }}
        </div>
        <div class="name">
          Sum(All Channel - Revenue) USD
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ listInfo.sumCost || '-' }}
        </div>
        <div class="name">
          Sum(All Channel - Cost) USD
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ listInfo.sumRoi || '-' }}
        </div>
        <div class="name">
          Sum(All Channel - Revenue) / Sum(All Channel - Cost)
        </div>
      </div>
    </div>

    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      style="width: 100%"
      border
    >
      <el-table-column
        label="Date"
        prop="dt"
        min-width="120px"
      />
      <el-table-column
        label="Country"
        prop="country"
        min-width="180px"
      />
      <el-table-column
        label="Channel"
        prop="channel"
        :formatter="channelFormatter"
        min-width="120px"
      />
      <el-table-column
        label="Revenue(USD)"
        prop="revenue"
        min-width="120px"
      />
      <el-table-column
        label="Cost(USD)"
        prop="costStr"
        min-width="120px"
      />
      <el-table-column
        label="ROI"
        prop="roi"
        min-width="100px"
      />
      <el-table-column
        label="订阅-续订收入（USD）"
        prop="revenue_next_subscribe"
        width="180px"
      />
      <el-table-column
        label="订阅-首次订阅（USD）"
        prop="revenue_first_subscribe"
        width="180px"
      />
      <el-table-column
        label="金币收入（USD）"
        prop="revenue_gold"
        width="180px"
      />
    </el-table>

    <Pagination
      v-show="!listInfo.loading"
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />
  </Layout>
</template>

<script>
import { format } from 'fecha'
import {
  getUserNationStatistics
} from '@/api/electronicEntity.js'
import {
  getFinancialDashboardList,
  addFinancialDownloadTask,
  getDownloadTaskList
} from '@/api/finance.js'

import {
  cloneDeep
} from 'lodash'

const getYesterday = () => {
  const hours = new Date().getHours()
  // 当天上午9点以后, 默认时间查询昨天信息, 否则查询前天
  const days = hours > 9 ? 1 : 2
  const targetDay = new Date((Date.now() - days * 24 * 60 * 60 * 1000)).getTime()
  return [format(targetDay, 'YYYY-MM-DD'), format(targetDay, 'YYYY-MM-DD')]
}

const formKey = 'REVENUE_FORM'
export default {
  name: 'DataWithRevenue',
  data () {
    return {
      // 平台1:android2:Ios.3:AllChannel
      channelList: [
        // {
        //   name: 'All Channel',
        //   value: 3
        // },
        {
          name: 'Google Play',
          value: 1
        },
        {
          name: 'IOS 内购',
          value: 2
        },
        {
          name: 'Stripe',
          value: 4
        },
        {
          name: 'Center Pay',
          value: 5
        },
        {
          name: 'Payer Max (北京时间12:00更新昨日数据)',
          value: 7
        }
      ],
      channelMap: {
        1: 'Google Play',
        2: 'IOS 内购',
        3: 'All Channel',
        4: 'Stripe',
        5: 'Center Pay',
        7: 'Payer Max'
      },
      nationList: [],
      filterNationList: [],
      formData: {
        dateRange: getYesterday(),
        channelList: [1, 2, 4, 5, 7],
        countryCodeList: [],
        pageNum: 1,
        pageSize: 20
      },
      pickerOptions: {
        disabledDate (time) {
          const hours = new Date().getHours()
          const days = hours > 9 ? 1 : 2
          const dateTime = time.getTime()
          return dateTime < new Date('2020-12-31').getTime() || dateTime > new Date((Date.now() - days * 24 * 60 * 60 * 1000)).getTime()
        }
      },
      searchRules: {
        dateRange: [
          { required: true, message: 'Date range is required' }
        ],
        countryCodeList: [
          { required: true, message: 'Country is required' }
        ],
        channelList: [
          { required: true, message: 'Channel is required' }
        ]
      },
      listInfo: {
        list: [],
        total: 0,
        sumRevenue: 0,
        sumCost: 0,
        sumRoi: 0,
        loading: true
      },
      downloadLoading: false
    }
  },
  created () {
    this.queryNationList()
      .then(() => {
        const storageFormData = sessionStorage.getItem(formKey)
        if (storageFormData) {
          this.listInfo.total = 9999
          this.formData = JSON.parse(storageFormData)
        }
        this.queryDataList()
      })
  },
  methods: {
    numFormatter (row, column, cellValue, index) {
      return !isNaN(cellValue) ? cellValue : '-'
    },
    channelFormatter (row, column, cellValue, index) {
      return this.channelMap[cellValue] || '-'
    },
    filterMethod (keyword) {
      this.filterNationList = keyword ? this.nationList.filter(item => {
        return item.nation.toLowerCase().startsWith(keyword.toLowerCase())
      }) : this.nationList
    },
    formatQuery (formData) {
      const form = cloneDeep(formData)
      const [startTime, endTime] = form.dateRange
      delete form.dateRange
      return {
        ...form,
        startTime,
        endTime,
        countryCodeList: form.countryCodeList.map(item => {
          return item.countryCode
        })
      }
    },

    changeSelectState (listKey, fullList, scope) {
      scope[listKey].length === fullList.length
        ? scope[listKey] = []
        : scope[listKey] = fullList
    },
    selectButtonLabel (currentList, fullList) {
      return currentList.length === fullList.length ? '取消全选' : '全选'
    },
    addFinancialDownloadTask () {
      this.downloadLoading = true
      const query = this.formatQuery(this.formData)
      delete query.pageNum
      delete query.pageSize
      addFinancialDownloadTask(query)
        .then(res => {
          if (res.code === 200) {
            this.$message.success('新建下载任务成功, 正在生成文件, 请稍等')
            this.askDownload(res.data.id)
          } else {
            this.downloadLoading = false
          }
        })
    },
    askDownload (taskId) {
      getDownloadTaskList({ id: taskId })
        .then(res => {
          if (res.code === 200) {
            const list = res.data.list || []
            const result = list.find(item => {
              return item.id === taskId
            })
            const STATUS_MAP = {
              building: 0,
              generated: 1,
              failed: 2
            }
            if (!result) {
              this.$message.error('新建任务失败, 请重试')
              this.downloadLoading = false
            } else if (result.status === STATUS_MAP.building) {
              setTimeout(() => { this.askDownload(taskId) }, 3 * 1000)
            } else if (result.status === STATUS_MAP.generated) {
              this.downloadLoading = false
              this.$message.success('开始下载')
              this.downloadFile(result.downloadUrl)
            } else if (result.status === STATUS_MAP.failed) {
              this.downloadLoading = false
              this.$message.error('生成文件失败, 请重试或者联系管理员')
            }
          } else {
            this.downloadLoading = false
          }
        })
    },
    downloadFile (url) {
      var a = document.createElement('a')
      a.href = url
      a.click()
      window.URL.revokeObjectURL(url)
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryDataList()
    },
    queryDataList () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.listInfo.loading = true
          const query = this.formatQuery(this.formData)
          sessionStorage.setItem(formKey, JSON.stringify(this.formData))
          getFinancialDashboardList(query)
            .then(res => {
              if (res.code === 200) {
                this.listInfo.list = res.data.list || []
                this.listInfo.total = res.data.total || 0
                this.listInfo.sumRevenue = res.data.sumRevenue
                this.listInfo.sumCost = res.data.sumCost
                this.listInfo.sumRoi = res.data.sumRoi
              }
            })
            .finally(() => {
              this.listInfo.loading = false
            })
        }
      })
    },
    queryNationList () {
      return getUserNationStatistics({
        searchWord: '',
        pageNum: 1,
        pageSize: 1000
      }).then(res => {
        if (res.code === 200) {
          const sortNationList = (res.data.list || []).sort((a, b) => {
            if (a.nation < b.nation) {
              return -1
            }
            if (a.nation > b.nation) {
              return 1
            }
            return 0
          })
          this.nationList = sortNationList
          this.filterNationList = sortNationList
          this.formData.countryCodeList = this.nationList
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .overview {
    display: flex;
    // padding: 0 0 20px 0;
    min-width: 880px;
    margin-bottom: 10px;
    .items {
      // width: 210px;
      padding: 0 30px;
      height: 100px;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, .12);
      border-radius: 4px;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .num {
        font-size: 26px;
        margin-bottom: 10px;
        .unit {
          font-size: 14px;
        }
      }
      .name {
        font-size: 12px;
      }
    }
    }
    .pre-header {
      height: 46px;
      .select-all {
        position: absolute;
        top: 0;
        z-index: 100;
        width: 100%;
        font-size: 14px;
        margin: 10px 0;
        padding: 10px 20px;
        cursor: pointer;
        background: #F5F7FA;
        box-sizing: border-box;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, .12);
        &::before {
          content: '';
          display: block;
          height: 10px;
          background: white;
          position: absolute;
          top: -10px;
          left: 0;
          width: 100%;
        }
        &:hover {
          color: cornflowerblue;
        }
      }
    }
.download-icon {
  display: flex;
  align-items: center;
  .icondown {
    font-size: 35px;
  }
}
</style>
